<template>
    <div class="col-md-5">
        <seccion-datos :titulo="asignacionmarcas" :botonGuardar="false" class="card card-warning">
            <div class="row">
                <div class="col-md-7">
                    <div class="input-group">
                        <select class="form-control" v-model="Seleccionado">
                            <option>{{ $t('general.seleccionaunamarca') }}</option>
                            <option v-for="marca in marcas" :value="marca.id_marca" :key="marca.id_marca"> {{ marca.nombre_marca }} </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-1">
                    <button class="btn btn-primary" @click="agregarMarca(id,Seleccionado)">{{ $t('general.anadir') }}</button>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-10">
                    <table class="table table-sm table-striped">
                        <td style="border-top:0px">&nbsp;</td>
                        <td v-if="marcasop" style="border-top:0px;font-weight:bold">{{ $t('general.sat') }}</td>
                        <tbody v-if="marcasop.length > 1"> 
                            <tr v-for="op in marcasop" :key="op.marcas_id_marca">
                                <td>{{ op.nombre_marca }}</td>
                                <td>
                                    <div class="col-md-7">
                                        <div class="custom-control custom-checkbox">
                                            <input :id="op.id_marca_operario" @click="agregarSat(op.marcas_id_marca, op.sat_marca)" v-model="op.sat_marca" v-bind:true-value="1" v-bind:false-value="0" type="checkbox"
                                                        class="custom-control-input">
                                            <label :for="op.id_marca_operario" class="custom-control-label"></label>
                                        </div>
                                    </div>
                                </td>
                                <td align="right">
                                    <div class="col-md-7">
                                        <button style="width:100px;height:100%" type="button" class="btn btn-block btn-outline-danger btn-xs" @click="eliminarMarca(op.marcas_id_marca)">{{ $t('general.eliminar') }}</button>
                                    </div> 
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td v-if="marcasop">{{ marcasop.nombre_marca }}</td>
                                <td v-if="marcasop">
                                    <div class="custom-control custom-checkbox">
                                        <input :id="marcasop.sat_marca" @click="agregarSat(marcasop.marcas_id_marca, marcasop.sat_marca)" v-model="marcasop.sat_marca"
                                                v-bind:true-value="1" v-bind:false-value="0" type="checkbox" class="custom-control-input">
                                        <label :for="marcasop.sat_marca" class="custom-control-label"></label>
                                    </div>
                                </td>
                                <td v-if="marcasop" align="right">
                                    <div class="col-md-6">
                                        <button type="button" class="btn btn-block btn-outline-danger btn-xs" @click="eliminarMarca(marcasop.marcas_id_marca)">{{ $t('general.eliminar') }}</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </seccion-datos>
    </div>
</template>
<script>
import $ from 'jquery';
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['id', 'operario'],
    data() {
        return {
            marcas: [],
            Seleccionado: 'Selecciona una marca',
            marcasop: [],
            asignacionmarcas: "Asignacion Marcas",
        }
    },
    watch: {
        operario() {
            this.cargardatos();
            this.cargarmasdatos();
        },
    },
    methods: {
        async cargardatos() {
            /* get  modulos/pwgsapi/index.php/marcas*/
            const api = new PwgsApi;
            const datos = await api.get('marcas');
            this.marcas = datos.datos;
            this.asignacionmarcas = this.$t("general.asignacionmarcas");
        },
        async cargarmasdatos() {
            /* get  modulos/pwgsapi/index.php/operarios/:id/marcas */
            const api = new PwgsApi;
            const datos = await api.get('operarios/' + this.id + '/marcas');
            this.marcasop = datos.datos;
            this.sat = this.marcasop.sat_marca;
        },
        async eliminarMarca(idmarca) {
            /* delete  modulos/pwgsapi/index.php/operarios/:id/marcas/:id_marca */
            const api = new PwgsApi;
            await api.delete('operarios/' + this.operario.id + '/marcas/' + idmarca);
            this.cargarmasdatos();
        },
        async agregarMarca(id,idmarca) {
            /* post  modulos/pwgsapi/index.php/operarios/:id/marcas */        
            let repetido = 0;
            if (this.marcasop.length > 1) {
                for (let marca of this.marcasop) {
                    if (idmarca == marca.marcas_id_marca) {
                        repetido = repetido + 1;
                    }                            
                }
            }
            if ((repetido < 1) && (idmarca != this.marcasop.marcas_id_marca)) {

                const api = new PwgsApi;
                const subidadatos = { id_marca: idmarca };
                await api.post('operarios/' + id + '/marcas/',subidadatos);
                this.cargarmasdatos();
            }
        },
        async agregarSat(idmarca,satmarca) {
            /* PUT  modulos/pwgsapi/index.php/operarios/:id/marca-sat/:id_marca/:valor */
            try {
                const api = new PwgsApi;
                const subidadatos = { sat_marca: satmarca };
                await api.put('operarios/' + this.operario.id + '/marca-sat/' + idmarca + '/' + satmarca, subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });

            }
              catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
            
        }     
    },
    mounted() {
        const $select = $('.select2.especialidades-guardias');
        $select.select2({ theme: 'bootstrap4' });
        this.cargardatos();
        this.cargarmasdatos();
    },
    }
</script>

